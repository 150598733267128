import React from 'react';
import { graphql } from 'gatsby';
import Meta from '@shared/Meta/Meta.query';
import DefaultLayout from '@layouts';
import PrivacyPage from '@components/PrivacyPage/PrivacyPage.query';

const Privacy = ({ data }) => {
  const privacyPageContent = data.allContentfulPrivacyPage.edges.map(
    edge => edge.node.privacyPageContent
  )[0];

  const globalSiteData = data.allContentfulGlobalSiteData.edges.map(
    edge => edge.node
  )[0];

  const {
    facebookLink,
    twitterLink,
    instagramLink,
    mailingAddressLine1,
    mailingAddressLine2,
    mailingAddressCity,
    mailingAddressState,
    mailingAddressZipCode,
    cookieBannerCopy,
    cookieBannerCtaCopy,
    phoneNumber,
    contactEmails,
    youTubeLink,
  } = globalSiteData;

  const footerData = {
    mailingAddressLine1,
    mailingAddressLine2,
    mailingAddressCity,
    mailingAddressState,
    mailingAddressZipCode,
    phoneNumber,
    facebookLink,
    twitterLink,
    instagramLink,
    contactEmails,
    youTubeLink,
  };

  const cookieBanner = {
    cookieBannerCopy,
    cookieBannerCtaCopy,
  };

  return (
    <DefaultLayout
      yellowLogo
      hideDonate
      footerData={footerData}
      cookieBanner={cookieBanner}
      pageName="Privacy"
    >
      <Meta {...globalSiteData} />
      <PrivacyPage privacyPageContent={privacyPageContent} />
    </DefaultLayout>
  );
};

export default Privacy;

// Graphql Query
export const pageQuery = graphql`
  {
    allContentfulGlobalSiteData {
      edges {
        node {
          ...Meta
        }
      }
    }

    allContentfulPrivacyPage {
      edges {
        node {
          privacyPageContent {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
