import React from 'react';
import idx from 'idx';
import PrivacyPage from './PrivacyPage';

const PrivacyPageQuery = props => {
  const privacyPageContent = idx(props, _ => _.privacyPageContent);

  return <PrivacyPage privacyPageContent={privacyPageContent} />;
};

export default PrivacyPageQuery;
