import React from 'react';
import Styles from './PrivacyPage.module.scss';

const PrivacyPage = ({ privacyPageContent }) => {
  return (
    <div className={Styles.privacyPage}>
      <div className={Styles.backgroundMask} />
      <div className={Styles.privacyPageContent}>
        <h1 className={Styles.headline}>
          Privacy
          <br />
          Policy
        </h1>
        <div
          className={Styles.privacyPageContent}
          dangerouslySetInnerHTML={{
            __html: privacyPageContent.childMarkdownRemark.html,
          }}
        />
      </div>
    </div>
  );
};

export default PrivacyPage;
